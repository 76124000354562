import { gql } from '@apollo/client';

export const GET_URL_BY_ID = gql`
  query getUrlById($shortUrl: String) {
    links(where: {shortUrl: {_eq: $shortUrl}}, limit: 1) {
        clicks
        date
        id
        label
        origUrl
        urlWithTracking
        shortUrl
    }
  }
`;
