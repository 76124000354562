import './App.css';
import { useParams } from "react-router-dom";
import { fetchDataWithApollo } from './utils/apolloClient';
import {GET_URL_BY_ID} from './database/getUrlById.gql'
import {useEffect} from "react";

const defaultUrl = "http://streetdeals.pl"

const redirectToPage = (url = defaultUrl) => {
  window.location.replace(url);
}

const App  = () => {
  const {shortUrl} = useParams();

  const loadUrl = async () => {
    console.log('shortUrl',shortUrl)

    if(shortUrl){

      const {data} = await fetchDataWithApollo(GET_URL_BY_ID, {shortUrl});
      console.log('data',data)
      if(data) {
        const urlData = data?.links[0];
        console.log('urlData',urlData)
        if(urlData){
          console.log('urlData',urlData)
          const {urlWithTracking} = urlData;
          if(urlWithTracking) {redirectToPage(urlWithTracking) } else {
            redirectToPage()
          }
        } else {
          redirectToPage()
        }
      } else {
        redirectToPage()
      }
    } else{
      redirectToPage()
    }
  }

  useEffect(() => {
    loadUrl()
  }, []);

  return null;
}

export default App;
