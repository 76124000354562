import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = new ApolloClient({
    uri: 'https://sdeals.herokuapp.com/v1/graphql',
    headers: {
        'content-type': 'application/json',
        'x-hasura-admin-secret': 'sdealspl2020',
    },
    cache: new InMemoryCache(),
});

export const fetchDataWithApollo = async (query, variables) => {
    const response = await client.query({
        query,
        variables
    });
    return response;
};
